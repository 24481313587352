import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'

import { Button } from '@happy-rabbit/component-library'
import FormErrors from './FormErrors'
import PriceBasedRate, { shippingRateHandle } from './PriceBasedRate'

const ShippingMethods = (props) => {
  const { cart, checkoutErrors, updateShippingLine } = props
  const [selectedRateHandle, setSelectedRateHandle] = useState(
    cart?.checkout?.shippingLine?.handle,
  )

  const shippingZone = cart?.data?.shippingZone
  const availableShippingRates = cart?.data?.availableShippingRates || []
  console.log({
    cart: cart.data,
    shippingZone: shippingZone,
    availableShippingRates: availableShippingRates,
  })

  useEffect(() => {
    if (!selectedRateHandle && cart?.checkout?.shippingLine?.handle) {
      setSelectedRateHandle(cart.checkout.shippingLine.handle)
    }
  }, [cart])

  function updateSelectedRate(val) {
    setSelectedRateHandle(val)
    updateShippingLine(val)
  }
  const availableRatesAndShippingZoneRates = useMemo(() => {
    const shippingZoneRates = shippingZone?.price_based_shipping_rates || []

    const availableRates = (cart?.data?.availableShippingRates || []).map(
      (availableRate) => {
        const shippingZoneRate = shippingZoneRates.find(
          (shippingZoneRate) =>
            shippingRateHandle(shippingZoneRate) === availableRate.handle,
        )
        return [availableRate, shippingZoneRate]
      },
    )

    const remainingShippingZoneRates = shippingZoneRates.filter(
      (shippingZoneRate) =>
        !availableRates.find(
          ([_, matchedShippingZoneRate]) =>
            matchedShippingZoneRate === shippingZoneRate,
        ),
    )
    return [
      ...availableRates,
      ...remainingShippingZoneRates.map((r) => [null, r]),
    ]
  }, [shippingZone, cart?.data?.availableShippingRates])

  // Is set to true if there is a shippingLine present but not included in the availableShippingRates.
  const selectedRateUnavailable = useMemo(() => {
    return (
      cart?.checkout?.shippingLine?.handle &&
      (cart?.data?.availableShippingRates || [])
        .map((rate) => rate.handle)
        .indexOf(cart?.checkout?.shippingLine?.handle) === -1
    )
  }, [cart])

  // const handleUpdateShippingRateClick = useCallback(() => {
  //   if (selectedRateHandle) {
  //     updateShippingLine(selectedRateHandle);
  //   }
  // }, [selectedRateHandle, updateShippingLine]);

  if (!shippingZone) return <p>No Shipping Methods available</p>

  return (
    <div className="">
      {availableShippingRates.length === 0 ? (
        <p className="text-sm text-depalmaDarkGray">
          <FormattedMessage
            id="checkout.please-enter-address"
            defaultMessage="Please enter the address before selecting a shipment method."
          />
        </p>
      ) : null}

      {selectedRateUnavailable ? (
        <div className="text-depalmaRed averta-italic">
          <FormattedMessage
            id="checkout.selected-shipping-rate-unavailable"
            defaultMessage="The selected shipping rate is no longer available, please choose another option."
          />
        </div>
      ) : null}
      <div className="mb-4">
        {availableRatesAndShippingZoneRates.map(
          ([availableRate, shippingZoneRate], i) => (
            <PriceBasedRate
              availableRate={availableRate}
              cart={cart}
              key={i}
              onChange={updateSelectedRate}
              shippingZoneRate={shippingZoneRate}
              selectedRateHandle={selectedRateHandle}
            />
          ),
        )}
      </div>

      <div className="">
        <FormErrors
          checkoutErrors={checkoutErrors}
          fields={['shippingRateHandle']}
          hideLabel={true}
        />
      </div>

      {/* <Button
        disabled={!selectedRateHandle}
        onClick={handleUpdateShippingRateClick}
        theme="ghost"
      >
        {cart?.checkout?.shippingLine && !selectedRateUnavailable ? <FaCheckCircle className="text-green-500 inline mr-1 mb-1" /> : null}
        <FormattedMessage
          id="checkout.update-shipping-method"
          defaultMessage="Update Shipping method"
        />
      </Button> */}
    </div>
  )
}

export default ShippingMethods
