import React from "react";
import { graphql } from "gatsby";

import Checkout from "../parts/Checkout";
import Layout from "../parts/Checkout/Layout";
import { useMetadata } from "../queries/metadata";
import { CMSProvider, ThemesProvider } from "@happy-rabbit/gatsby-sanity-cms";

const pageConfigCheckout = { slug: { current: '/checkout' }, pageSeo: { title: { en: 'Checkout' } } };

const CheckoutTemplate = props => {
  const {
    data,
    pageContext,
    path,
  } = props;

  const site = data.allSanitySite.edges[0].node;
  const { lang } = pageContext;
  const siteMetadata = useMetadata();

  const {
    themeReferences: themes,
  } = site || {};

  return (
    <CMSProvider context={{ ...pageContext, site, siteMetadata, pageConfig: pageConfigCheckout, path }}>
      <ThemesProvider themes={themes}>
        <Layout lang={lang}>
          <Checkout />
        </Layout>
      </ThemesProvider>
    </CMSProvider>
  )
}

export const pageQuery = graphql`
query($siteId: String!) {
  allSanitySite (filter: {_id: {eq: $siteId}}) {
    edges {
      node {
        _id

        defaultCurrencyCode
        slug {
          current
        }
        themeReferences {
          ...SanityThemeFragment
        }
      }
    }
  }
}
`;

export default CheckoutTemplate;
