import React from "react";
import { FormattedMessage } from "react-intl";

const FormErrors = props => {
  const {
    checkoutErrors,
    fields,
    hideLabel = false,
    intlPrefix = '',
  } = props;

  return fields.map((field, i) => checkoutErrors[field] ? (
    <p key={i} className="text-depalmaRed text-sm averta-italic">
      {!hideLabel ? (
        <>
          <FormattedMessage
            id={`${intlPrefix}${field}`}
            defaultMessage={field}
          />
          {`: `}
        </>
      ) : null}
      {checkoutErrors[field].join(', ')}
    </p>
  ) : null);
};

export default FormErrors;
