import React from "react";
import { IntlProvider } from "react-intl";
import clsx from "clsx";

import CountryProvider from "../../contexts/CountryContext";
import CartProvider from "../../contexts/CartContext";
import translations from "../../translations";

import Header from "./Header";
import Footer from "./Footer";
import GDPRConsent from "../GDPRConsent";

const Layout = props => {
  const {
    children,
    lang = 'en',
    pageDesign = null,
  } = props;

  const {
    startContentAt = 'below-header',
  } = pageDesign || {};

  return (
    <CountryProvider>
      <CartProvider>
        <IntlProvider messages={translations[lang]} locale={lang} defaultLocale="en">
          <div className="flex flex-col min-h-screen justify-between">
            <Header />

            <main className={clsx("mb-auto")}>
              {children}
            </main>

            <Footer />
            <GDPRConsent />
          </div>
        </IntlProvider>
      </CartProvider>
    </CountryProvider>
  );
};

export default Layout;
