import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import Price from "../../components/Price";

export const shippingRateHandle = (rate) => encodeURIComponent(`shopify-${rate.name}-${rate.price}`);


const PriceBasedRate = props => {
  const {
    available,

    // This is the available rate loaded from the checkout when
    // shipping address has been set. Can be null if presenting
    // a shippingZoneRate that is not available.
    availableRate,

    cart,
    onChange,
    rate,
    selectedRateHandle,

    // This is a rate object loaded from the possible shippingRates
    // for the country/zone. Should only be null when an available
    // rate could not be matched to a rate from the shippingZone,
    // but that should not happen unless there has been some changes
    // or something else is wrong.
    shippingZoneRate,
  } = props;

  // const { name, price } = rate;
  const { handle, title, priceV2 } = availableRate || {};
  const { name, price, max_order_subtotal, min_order_subtotal } = shippingZoneRate || {};

  const [checked, setChecked] = useState(selectedRateHandle && handle === selectedRateHandle);
  useEffect(() => {
    setChecked(selectedRateHandle && handle === selectedRateHandle);
  }, [selectedRateHandle]);

  const [min, max] = useMemo(() => {
    const max = max_order_subtotal ? parseFloat(max_order_subtotal) : null;
    const min = min_order_subtotal ? parseFloat(min_order_subtotal) : null;

    return [min, max];
  }, [cart, shippingZoneRate])

  const [calcAmount, calcMin, calcMax, withCurrency] = useMemo(() => {
    const number = parseFloat(price);
    if (cart?.checkout?.currencyCode === 'EUR') {
      return [
        number / 10,
        min ? min / 10 : null,
        max ? max / 10 : null,
        'EUR',
      ];
    } else {
      return [number, min, max, 'SEK'];
    }
  }, [cart, price, priceV2, min, max]);

  return (
    <label className={clsx("flex py-2 justify-between", { "text-gray-500 line-through": !availableRate })}>
      <div className="flex-0 mr-2">
        <input
          checked={checked}
          disabled={!availableRate}
          name="shipping-methods"
          onChange={() => {
            setChecked(true);
            onChange(handle)
          }}
          type="radio"
          value={handle}
        />
      </div>
      <div className="flex-1 mr-2">
        <div>{title || name}</div>
        <div className="text-xs">
          {calcMin ? (
            <FormattedMessage
              id="checkout.shippingMethods.orders-above"
              defaultMessage="Orders above {price}"
              values={{
                price: <Price
                  amount={parseFloat(calcMin)}
                  currencyCode={withCurrency}
                />
              }}
            />
          ) : null}
          {calcMax ? (
            <FormattedMessage
              id="checkout.shippingMethods.orders-below"
              defaultMessage="Orders below {price}"
              values={{
                price: <Price
                  amount={cart?.checkout?.currencyCode === 'SEK' ? Math.round(calcMax) : calcMax}
                  currencyCode={withCurrency}
                />
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="flex-0 text-right">
        {calcAmount === 0 ? (
          <FormattedMessage
            id="checkout.free"
            defaultMessage="Free"
          />
        ) : (
          <Price
            amount={parseFloat(calcAmount)}
            currencyCode={withCurrency}
          />
        )}
      </div>
    </label>
  );
};

export default PriceBasedRate;
