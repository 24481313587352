import clsx from "clsx";
import React, { useEffect, useState } from "react";
// import { FaCheckCircle } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

// import { Button } from "@happy-rabbit/component-library";
import { useCartStateContext } from "../../contexts/CartContext";
import FormErrors from "./FormErrors";

const ContactDetails = props => {
  const { checkoutErrors, forCompany = false } = props;
  const { cart, updateContactDetails } = useCartStateContext();

  const { handleSubmit, register, setValue, getValues } = useForm({
    mode: "onBlur",
  });

  const [cachedConsent, setCachedConsent] = useState(null);
  useEffect(() => {
    const customAttributes = cart?.checkout?.customAttributes || [];
    const newsletterConsent = customAttributes.find(ca => ca.key === 'newsletterConsent');
    if (newsletterConsent && newsletterConsent.value !== cachedConsent) {
      setCachedConsent(newsletterConsent.value);
      setValue('newsletterConsent', newsletterConsent.value === 'true');
    }
  }, [cart, cachedConsent]);

  useEffect(() => {
    setValue('email', cart?.checkout?.email);
  }, [cart?.checkout?.email]);

  const handleCheckboxChange = (payload) => {
    const emailValue = getValues('email')
    console.log(emailValue)
    console.log(cart?.checkout?.email)
    handleSubmit(updateContactDetails({
      email: emailValue,
      newsletterConsent: payload.target.checked
    }))
  };

  return (
    <div>
    {/* <form onSubmit={handleSubmit(updateContactDetails)}> */}
      <label className="block text-gray-700 text-sm mb-2">
        <FormattedMessage
          id="checkout.contactDetails.email"
          description="The email address"
          defaultMessage="Email"
        />
        <input
          {...register('email')}
          className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
          type="text"
          onBlur={handleSubmit(updateContactDetails)}
        />
      </label>
      <div className="">
        <FormErrors
          checkoutErrors={checkoutErrors}
          fields={['email']}
          intlPrefix={'checkout.contactDetails.'}
          hideLabel={true}
        />
      </div>

      <label className={clsx("text-gray-700 text-sm mb-2 flex")}>
        <input
          {...register('newsletterConsent')}
          className="mr-2"
          defaultChecked={true}
          type="checkbox"
          onChange={handleCheckboxChange}
        />
        <span className={clsx("averta-bold")}>
          <FormattedMessage
            id="checkout.contactDetails.newsletter-consent"
            defaultMessage="Keep me up to date on news and offers"
          />
        </span>
      </label>

      {/* <Button type="submit" theme="ghost">
        {cart?.checkout?.email ? <FaCheckCircle className="text-green-500 inline mr-1 mb-1" /> : null}
        <FormattedMessage
          id="checkout.update-contact-details"
          defaultMessage="Update Contact Details"
        />
      </Button> */}
    {/* </form> */}
    </div>
  );
};

export default ContactDetails;
