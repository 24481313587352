import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { navigate } from "gatsby";
import CookieConsent, { Cookies } from "react-cookie-consent";

import { setCookie } from "../../utils/cookies";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";

const GDPRConsent = props => {
  const { site, lang } = useCMSContext();

  const [accepted, setAccepted] = useState(false);
  useEffect(() => {
    setAccepted(Cookies.get("gatsby-gdpr-zendesk-chat") === 'true');
  }, []);

  useEffect(() => {
    if (window) {
      window.zESettings = {
        cookies: accepted,
        zIndex: 998,
      };
    }
  }, [accepted]);

  return (
    <CookieConsent
      location="bottom"
      disableStyles={true}
      containerClasses="containerClasses fixed bottom-0 w-full z-999 flex justify-space-between items-center bg-black text-white flex-wrap"
      contentClasses="contentClasses m-4 flex-auto w-full sm:w-48 text-sm flex items-center"
      overlayClasses="overlayClasses"
      buttonClasses="bg-white text-black hover:bg-black hover:text-white border border-1 border-black hover:border-white m-4 px-4 py-2"
      declineButtonClasses="bg-white text-black hover:bg-black hover:text-white border border-1 border-black hover:border-white m-4 px-4 py-2"
      buttonText={
        <FormattedMessage
          id="gdpr-cookies-consent.accept"
          description="GDPR Accept"
          defaultMessage="Accept"
        />
      }
      cookieName="gatsby-gdpr-google-analytics"
      onAccept={() => {
        setCookie("gatsby-gdpr-google-tagmanager", "true");
        setCookie("gatsby-gdpr-facebook-pixel", "true");
        setCookie("gatsby-gdpr-zendesk-chat", "true");
        setAccepted(true);
      }}
      onDecline={() => {
        // Not actually a decline, but we use the decline button as a "Read More" button
        const pathPrefix = site._id === 'global' ? '' : `/${site.slug.current}/${lang}`;
        navigate(`${pathPrefix}/privacy`);
      }}
      setDeclineCookie={false}
      declineButtonText={
        <FormattedMessage
          id="gdpr-cookies-consent.read-more"
          description="GDPR Read more"
          defaultMessage="Read more"
        />
      }
      enableDeclineButton={true}
      hideOnDecline={false}
    >
      <FormattedMessage
        id="gdpr-cookies-consent.information"
        description="GDPR information and consent request"
        defaultMessage="To give you a better service, this website uses cookies! By continuing to use the website, you accept our privacy and cookie policy."
      />
    </CookieConsent>
  );
};

export default GDPRConsent;
