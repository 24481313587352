import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { Button } from "@happy-rabbit/component-library";
import Price from "../../components/Price";
import FormErrors from "./FormErrors";

export const shippingRateHandle = (rate) => encodeURIComponent(`shopify-${rate.name}-${rate.price}`);

const PriceBasedRate = props => {
  const { cart, onChange, rate, selectedRateHandle } = props;
  const { handle, title, priceV2 } = rate;

  //const handle = shippingRateHandle(rate);

  const [checked, setChecked] = useState(handle === selectedRateHandle);
  useEffect(() => {
    setChecked(handle === selectedRateHandle);
  }, [selectedRateHandle]);

  return (
    <label className={clsx("flex py-2 justify-between", { "text-gray-500 line-through": false })}>
      <div className="flex-0 mr-2">
        <input
          checked={checked}
          name="shipping-methods"
          onChange={() => {
            setChecked(true);
            onChange(handle)
          }}
          type="radio"
          value={handle}
        />
      </div>
      <div className="flex-1">
        <div>{title}</div>
      </div>

      <div className="flex-0 text-right">
        {priceV2?.amount === 0 ? (
          <FormattedMessage
            id="checkout.free"
            defaultMessage="Free"
          />
        ) : (
          <Price {...priceV2} />
        )}
      </div>
    </label>
  );
};

const ShippingMethodsNonSEK = props => {
  const { cart, checkoutErrors, updateShippingLine } = props;
  const [selectedRateHandle, setSelectedRateHandle] = useState(cart?.checkout?.shippingLine?.handle);

  const availableShippingRates = cart?.data?.availableShippingRates || [];

  // const handleUpdateShippingRateClick = useCallback(() => {
  //   if (selectedRateHandle) {
  //     updateShippingLine(selectedRateHandle);
  //   }
  // }, [selectedRateHandle, updateShippingLine]);

  function updateSelectedRate (val) {
    setSelectedRateHandle(val)
    updateShippingLine(val);
  }

  return (
    <div className="">
      <div className="mb-4">
        {availableShippingRates.length === 0 ? (
          <p className="text-sm text-depalmaDarkGray">
            <FormattedMessage
              id="checkout.please-enter-address"
              defaultMessage="Please enter the address before selecting a shipment method."
            />
          </p>
        ) : null}

        {availableShippingRates.map((rate, i) => (
          <PriceBasedRate
            cart={cart}
            key={i}
            onChange={updateSelectedRate}
            rate={rate}
            selectedRateHandle={selectedRateHandle}
          />
        ))}
      </div>

      <div className="">
        <FormErrors
          checkoutErrors={checkoutErrors}
          fields={['shippingRateHandle']}
          hideLabel={true}
        />
      </div>

      {/* <Button
        disabled={!selectedRateHandle}
        onClick={handleUpdateShippingRateClick}
        theme="ghost"
      >
        {cart?.checkout?.shippingLine ? <FaCheckCircle className="text-green-500 inline mr-1 mb-1" /> : null}
        <FormattedMessage
          id="checkout.update-shipping-method"
          defaultMessage="Update Shipping method"
        />
      </Button> */}
    </div>
  );
};

export default ShippingMethodsNonSEK;
