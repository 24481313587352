import React from "react";

const Footer = props => {

  return (
    <footer>
      <div className="flex-0 mb-12 pt-12">
        <div className="text-sm flex justify-center flex-col sm:flex-row items-center sm:items-stretch">
          <div>© {new Date().getFullYear()} DePalma Workwear Limited</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
