import { Link } from "gatsby";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import { PageLink } from "@happy-rabbit/gatsby-sanity-cms/components";

import logo2 from "../../images/depalma_logos-01.svg";

const Header  = props => {
  const { site, lang } = useCMSContext();

  const rootPath = useMemo(
    () => (site._id === "global" ? "/" : `/${site.slug.current}/${lang}/`),
    [site, lang]
  );

  return (
    <div className="py-2 px-6 sm:px-0">
      <div className="container mx-auto flex justify-start items-center mb-8 pb-4 border-b border-gray-400">
        <div className="flex-0 text-center pr-2">
          <Link to={rootPath}>
            <img
              src={logo2}
              alt="DePalma Logo"
            />
          </Link>
        </div>

        <div className="flex-0 border-l border-gray-400 text-xl pl-2">
          Checkout
        </div>

        <div className="flex-auto text-right hidden sm:block">
          <PageLink buttonTheme="ghost" showAsButton={true} pageConfig={{slug: { current: '/' }}} >
            <FormattedMessage
              id="checkout.continue-shopping"
              defaultMessage="Continue Shopping"
            />
          </PageLink>
        </div>
      </div>
    </div>
  );
};

export default Header;