import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { CountrySelect } from "../../components/FieldSelect";
import { Button } from "@happy-rabbit/component-library";
import { useCountryStateContext } from "../../contexts/CountryContext";
import { getLocaleValue, useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import { useCartStateContext } from "../../contexts/CartContext";
import FormErrors from "./FormErrors";

const ShippingAddress = props => {
  const { checkoutErrors, forCompany = false } = props;
  const { lang } = useCMSContext();
  const { country } = useCountryStateContext();
  const { cart, updateShippingAddress } = useCartStateContext();

  const shippingAddress = cart?.checkout?.shippingAddress ?? {};

  const { control, formState, handleSubmit, register, reset, setValue } = useForm({
    mode: "onBlur",
  });
  const { errors = {} } = formState;
  useEffect(() => {
    const { firstName, lastName, phone, company, address1, address2, city, zip, state } = shippingAddress || {};
    reset({ firstName, lastName, phone, company, address1, address2, city, zip, state, country: country?.slug?.current });
  }, [country, reset]);

  const countryOptions = useMemo(
    () => country ?
      [
        {
          label: getLocaleValue(country.name, lang),
          value: country.slug.current,
          country,
        },
      ] : [],
    [country, lang]
  );

  const [cachedVat, setCachedVat] = useState(null);
  useEffect(() => {
    if (!forCompany)
      return;

    const customAttributes = cart?.checkout?.customAttributes || [];
    const vatNumber = customAttributes.find(ca => ca.key === 'vatNumber');
    if (vatNumber && vatNumber.value !== cachedVat) {
      setCachedVat(vatNumber.value);
      setValue('vatNumber', vatNumber.value);
    }
  }, [cart, cachedVat]);

  return (
    <form onSubmit={handleSubmit(updateShippingAddress)}>
      <div className="md:grid md:grid-cols-3 gap-4">
        <label className="block text-gray-700 text-sm mb-2">
          <FormattedMessage
            id="checkout.shippingAddress.firstName"
            description="First Name"
            defaultMessage="First Name"
          />
          <input
            {...register('firstName')}
            className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
            type="text"
            onBlur={handleSubmit(updateShippingAddress)}
          />
        </label>
        <label className="block text-gray-700 text-sm mb-2">
          <FormattedMessage
            id="checkout.shippingAddress.lastName"
            description="Last Name"
            defaultMessage="Last Name"
          />
          <input
            {...register('lastName')}
            className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
            required={true}
            type="text"
            onBlur={handleSubmit(updateShippingAddress)}
          />
        </label>
        <label className="block text-gray-700 text-sm mb-2">
          <FormattedMessage
            id="checkout.shippingAddress.phone"
            description="Phone"
            defaultMessage="Phone"
          />
          <input
            {...register('phone')}
            className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
            type="text"
            onBlur={handleSubmit(updateShippingAddress)}
          />
        </label>
      </div>
      <div className="">
        <FormErrors
          checkoutErrors={checkoutErrors}
          fields={['shippingAddress.firstName', 'shippingAddress.lastName', 'shippingAddress.phone']}
          intlPrefix={'checkout.'}
        />
      </div>

      {forCompany? (
        <>
          <label className="block text-gray-700 text-sm mb-2">
            <FormattedMessage
              id="checkout.shippingAddress.company"
              description="The company name"
              defaultMessage="Company"
            />
            <input
              {...register('company')}
              className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
              type="text"
              onBlur={handleSubmit(updateShippingAddress)}
            />
          </label>
          <div className="">
            <FormErrors
              checkoutErrors={checkoutErrors}
              fields={['shippingAddress.company']}
              intlPrefix={'checkout.'}
            />
          </div>

          <label className="block text-gray-700 text-sm mb-2">
            <FormattedMessage
              id="checkout.company-vat-number"
              description="The company vat number"
              defaultMessage="VAT Number"
            />
            <input
              {...register('vatNumber')}
              className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
              required={true}
              type="text"
              onBlur={handleSubmit(updateShippingAddress)}
            />
          </label>
        </>
      ) : null}

      <label className="block text-gray-700 text-sm mb-2">
        <FormattedMessage
          id="checkout.shippingAddress.address1"
          description="The street name and house number"
          defaultMessage="Street"
        />
        <input
          {...register('address1')}
          className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
          required={true}
          type="text"
          onBlur={handleSubmit(updateShippingAddress)}
        />
      </label>

      <div className="">
        <FormErrors
          checkoutErrors={checkoutErrors}
          fields={['shippingAddress.address1']}
          intlPrefix={'checkout.'}
        />
      </div>

      <label className="block text-gray-700 text-sm mb-2">
        <FormattedMessage
          id="checkout.shippingAddress.address2"
          description="The second address line"
          defaultMessage="Address 2"
        />
        <input
          {...register('address2')}
          className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
          type="text"
          onBlur={handleSubmit(updateShippingAddress)}
        />
      </label>

      <div className="">
        <FormErrors
          checkoutErrors={checkoutErrors}
          fields={['shippingAddress.address2']}
          intlPrefix={'checkout.'}
        />
      </div>

      <div className={clsx("grid md:flex gap-4", country?.slug?.current === 'se' ? 'md:grid-cols-2' : 'md:grid-cols-3')}>
        <label className={clsx("block text-gray-700 text-sm mb-2")}>
          <FormattedMessage
            id="checkout.shippingAddress.zip"
            description="The Zip code"
            defaultMessage="Zip / Postal"
          />
          <input
            {...register('zip')}
            className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
            type="text"
            required={true}
            onBlur={handleSubmit(updateShippingAddress)}
          />
        </label>
        <label className={clsx("block text-gray-700 text-sm mb-2")}>
          <FormattedMessage
            id="checkout.shippingAddress.city"
            description="The city"
            defaultMessage="City"
          />
          <input
            {...register('city')}
            className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
            type="text"
            required={true}
            onBlur={handleSubmit(updateShippingAddress)}
          />
        </label>

        {country?.slug?.current === "se" ? null : (
          <label className="block text-gray-700 text-sm mb-2">
            <FormattedMessage
              id="checkout.shippingAddress.province"
              description="The Province"
              defaultMessage="State / Province"
            />
            <input
              {...register('province')}
              className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
              type="text"
              onBlur={handleSubmit(updateShippingAddress)}
            />
          </label>
        )}
      </div>
      <div className="">
        <FormErrors
          checkoutErrors={checkoutErrors}
          fields={['shippingAddress.city', 'shippingAddress.zip', 'shippingAddress.province']}
          intlPrefix={'checkout.'}
        />
      </div>

      <Controller
        name="country"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) =>
          <CountrySelect
            {...field}
            attribute={"country"}
            defaultValue={field.value}
            disabled={true}
            errors={errors}
            label={
              <FormattedMessage
                id="checkout.shippingAddress.country"
                description="Country Label"
                defaultMessage={`Country`}
              />
            }
            options={countryOptions}
          />
        }
      />
      <div className="">
        <FormErrors
          checkoutErrors={checkoutErrors}
          fields={['shippingAddress.country']}
          intlPrefix={'checkout.'}
        />
      </div>

      {/* <Button type="submit" theme="ghost">
        {cart?.checkout?.shippingAddress ? <FaCheckCircle className="text-green-500 inline mr-1 mb-1" /> : null}
        <FormattedMessage
          id="checkout.update-shipping-address"
          defaultMessage="Update Shipping Address"
        />
      </Button> */}
    </form>
  );
};

export default ShippingAddress;
